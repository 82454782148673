function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
    });
  });
}


$(function() {
/*********************************************************************
  common
*********************************************************************/
slideout_menu();

/*********************************************************************
  index
*********************************************************************/
$('.js-slide').slick({
    infinite:true,
    slideToScroll:1,
    slidesToShow:3,
    arrows:true,
    dots:false,
    variableWidth:true,
    prevArrow: '<img src="/assets/img/common/icon_slide_arrow.png" class="slide-arrow prev-arrow">',
    nextArrow: '<img src="/assets/img/common/icon_slide_arrow.png" class="slide-arrow next-arrow">',
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
       }
    }]
  });
});
$(window).on('load',function(){


});
$(window).on('scroll',function(){
});
$(window).on('resize',function(){
});
